import { Layout } from "@/components/layout";

export function NotFoundPage() {
  return (
    <Layout>
      <h1>404</h1>
      <p>Page not found</p>
    </Layout>
  );
}

export default NotFoundPage;
